.tacoCardSection {
  display: flex;
  margin: 0;
  background-color: #ffbd0c;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 382px;
}

.tacoCardExplainer {
  margin: 0;
  max-width: 40%;
  text-align: left;
  margin-left: 14%;
  padding-right: 2%;
}

.heading {
  font-family: "ForgiaW01Outside";
  font-size: 3em;
  color: #351e0c;
  margin-top: 12px;
  margin-bottom: 12px;
}

.description {
  color: #351e0c;
  font-family: "BrandonGrotesqueBlackLight", "Baloo 2";
  font-weight: bolder;
  font-size: 1.2em;
  line-height: 1.5em;
  padding-top: 0.4em;
}

.tacoCardImageContainer {
  align-items: center;
}

.tacoCardImage {
  display: block;
  width: 55%;
  margin-top: 6%;
  margin-left: 8%;
}

@media screen and (max-width: 1600px) {
  .tacoCardExplainer {
    width: 80%;
    padding-right: 5%;
  }
}

@media screen and (max-width: 1500px) {
  .tacoCardExplainer {
    width: 80%;
    padding-right: 5%;
  }
}

@media screen and (max-width: 1400px) {
  .tacoCardImage {
    width: 62%;
  }

  .tacoCardExplainer {
    padding-bottom: 2%;
    padding-top: 2%;
    padding-right: 0;
  }
}

@media screen and (max-width: 1250px) {
  .tacoCardExplainer {
    padding-right: 2%;
  }
}

@media screen and (max-width: 1201px) {
  .tacoCardImage {
    min-width: 72%;
  }

  .tacoCardExplainer {
    max-width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
  }
}

@media screen and (max-width: 1090px) {
  .tacoCardExplainer {
    padding-top: 4%;
    padding-bottom: 4%;
  }

  .heading {
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .tacoCardImage {
    margin-left: 8%;
  }
}

@media screen and (max-width: 1047px) {
  .tacoCardSection {
    flex-direction: column;
    padding-right: 0;
  }

  .tacoCardExplainer {
    text-align: center;
    margin-top: 5%;
    margin-bottom: 3%;
    padding-right: 0;
    padding-top: 5%;
    margin: auto;
  }

  .description {
    margin-left: 15%;
    margin-right: 15%;
  }

  .heading {
    font-size: 3em;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .tacoCardButton {
    margin: auto;
  }

  .tacoCardImage {
    margin: auto;
    min-width: 38%;
    width: 34%;
  }

  .description {
    margin-top: 5%;
    margin-bottom: 5%;
  }
}

@media screen and (max-width: 870px) {
  .tacoCardImage {
    min-width: 45%;
  }
}

@media screen and (max-width: 650px) {
  .tacoCardImage {
    margin: auto;
    min-width: 65%;
    width: 65%;
  }

  .tacoCardSection {
    padding-top: 5%;
  }

  .description {
    font-size: 1.2em;
  }

  .heading {
    font-size: 2em;
  }
}
