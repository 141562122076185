@font-face {
  font-family: "ForgiaW01Outside";
  src: local("ForgiaW01Outside"),
  url("./fonts/ForgiaW01Outside/ForgiaW01Outside.ttf") format("truetype");
}

 @font-face {
  font-family: "BrandonGrotesqueBlack";
  src: local("BrandonGrotesqueBlack"),
  url("./fonts/BrandonGrotesqueBlack/BrandonGrotesqueBlack.otf") format("opentype");
 }

 @font-face {
  font-family: "BrandonGrotesqueBlackLight";
  src: local("BrandonGrotesqueLight"),
  url("./fonts/BrandonGrotesqueBlack/BrandonGrotesqueBlackLight.otf") format("opentype");
 }