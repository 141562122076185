.fab {
  font-size: 28px;
  color: #fff;
  text-shadow: 0.1px 0.1px 9px rgba(0, 0, 0, 0.358);
}

.fab:hover {
  color: #dc7f06b7;
  transition: all 0.5s ease-out;
}

@media screen and (min-width: 991px) {
  .container {
    display: flex;
    flex-direction: row;
    max-width: 70%;
    margin: 1em auto;
    padding: 25px;
    justify-content: flex-end;
    align-items: center;
  }

  .profileContent {
    margin-left: 30px;
    display: flex;
  }
}

@media screen and (max-width: 1050px) {
  .container {
    display: flex;
    flex-direction: row;
    max-width: 90%;
    margin: 0 auto;
    padding: 25px;
    justify-content: flex-end;
    align-items: center;
  }

  .profileContent {
    margin-left: 30px;
  }

  .explainer {
    text-align: center;
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    font-size: 1.3em;
  }
}

@media screen and (max-width: 770px) {
  .container {
    display: flex;
    flex-direction: column;
    max-width: 80%;
    margin: 1em auto;
    padding: 25px;
    justify-content: flex-end;
    align-items: center;
  }

  .meet {
    margin-bottom: 10%;
  }

  .name {
    display: block;
    color: #dc7f06;
    font-size: 20px;
    text-align: center;
    line-height: 1.3em;
    margin: 0 auto;
  }

  .role {
    margin: 0 auto;
  }

  .profileContent {
    padding-top: 8%;
    margin-left: 0;
  }
}

.role {
  font-size: 1.4em;
  font-weight: 500;
  text-align: center;
  font-family: "Bungee";
  line-height: 1.5em;
}

.bio {
  font-size: 1.2em;
  font-weight: 500;
  text-align: left;
  margin: 01em 0;
  line-height: 1.5em;
}

.newsSection {
  font-family: "BrandonGrotesqueBlackLight";
  padding-top: 3em;
  position: relative;
  padding-bottom: 4em;
  display: flex;
  flex-direction: column;
  background-color: #fbebb9;
  min-height: 100vh;
}

.borderBreak {
  background-color: #ffebb1;
  height: 5px;
  width: 100%;
  margin: 0 auto;
}

.border {
  background-color: #dc7f06;
  height: 100%;
  width: 80%;
  margin: 0 auto;
}

.profileImage {
  width: 250px;
  display: flex;
  max-height: 250px;
  max-width: none;
  border: 1px solid #dc7f06;
  box-shadow: 1px 3px 0 rgb(255, 255, 255 / 98%);
  object-fit: cover;
  border-radius: 100%;
}

.profileContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  margin-top: 0;
}

.name {
  color: #dc7f06;
  font-family: "BrandonGrotesqueBlack";
  font-size: 2em;
  line-height: 2.5em;
}

.socialLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socialsWrapper {
  display: flex;
  margin-bottom: 0;
  padding-left: 0;
  color: #fbebb9;
}

.social {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  margin-right: 1em;
}

.socialLink {
  max-height: 39px;
  padding: 10px 5px;
  border-radius: 10px;
  transition: background-color 350ms, color 350ms;
  color: #ffbd0c;
  font-size: 22px;
  line-height: 1.111em;
  text-decoration: none;
  white-space: nowrap;
}

.socialIcon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  margin-left: 8px;
}

.explainer {
  text-align: center;
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  font-size: 1.5em;
  line-height: 1.5em;
}

.summary {
  margin-top: 3em;
  font-size: 0.9em;
  width: 80%;
  margin: 5% auto;
  text-align: left;
}

.newsOpenerSummary {
  margin-top: 3em;
  font-size: 1em;
  width: 80%;
  margin: 5% auto;
  text-align: center;
}

.meet {
  font-family: "Bungee";
  font-size: 1.5em;
  line-height: 1.4em;
  font-family: "ForgiaW01Outside";
  color: #ffbd0c;
  text-shadow: -1px 0 #dc7f06, 0 1px #dc7f06, 1px 0 #dc7f06, 0 -1px #dc7f06;
}

.tacoTuesdayTitle {
  font-family: "Bungee";
  font-size: 1.5em;
  line-height: 1.4em;
  font-family: "ForgiaW01Outside";
  color: #ffbd0c;
  text-shadow: -1px 0 #dc7f06, 0 1px #dc7f06, 1px 0 #dc7f06, 0 -1px #dc7f06;
}

.newsBullet {
  font-family: "Bungee";
  color: #351e0c;
  font-style: italic;
  text-align: center;
}

@media screen and (max-width: 570px) {
  .name {
    margin: 0 auto;
    line-height: 1em;
  }

  .role {
    margin-top: 1em;
  }

  .bio {
    text-align: center;
  }

  .socialLinks {
    margin: 0 auto;
    padding-left: 15px;
  }

  .summary {
    width: 100%;
    text-align: center;
  }

  .newsBullet {
    margin-top: 10%;
  }
}
