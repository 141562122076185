.navbar {
  height: 60px;
  width: 100%;
  margin: 0 auto;
  position: sticky;
  background-color: #fbebb9;
  z-index: 1000;
  top: 0;
  transition: all ease 0.5s;
  display: flex;
  justify-content: center;
}

.navbar.active {
  background: #ffebb1;
  transition: all ease 1s;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 82%;
}

.navbar-logo-container {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.logo {
  width: 120px;
  height: auto;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.navbar-container > .nav-menu {
  align-items: center;
  align-content: center;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  max-width: 100%;
}

.buy-button-container {
  width: auto;
  height: auto;
  display: flex;
  justify-content: flex-end;
  text-decoration: none;
  margin-left: 80px;
}

.nav-item {
  height: 60px;
  cursor: pointer;
  padding-bottom: 3%;
}

.nav-links {
  color: #1eaa40;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.7rem 1rem;
  height: 100%;
  font-family: "bungee";
}

.nav-links:hover {
  border-bottom: 4px solid #1eaa40;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1320px) {
  .navbar-container {
    max-width: 90%;
    width: 90%;
  }
}

@media screen and (max-width: 1200px) {
  .navbar-container {
    max-width: 95%;
    width: 95%;
  }

  .nav-links {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.65rem;
  }
}

@media screen and (max-width: 960px) {
  .navbar {
    background: linear-gradient(
      0deg,
      rgba(28, 17, 1, 0.8491771708683473) 0%,
      rgba(45, 28, 4, 0.8071603641456583) 100%
    );
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
    background: #fbebb9;
    width: 100%;
  }

  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    margin-top: -10px;
    width: 37%;
  }

  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 20px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    margin-top: 8%;
  }

  .nav-menu.active {
    background: #fbebb9;
    left: -28px;
    padding: 0;
    padding-right: 10%;
    opacity: 0.98;
    transition: all 0.5s ease;
    z-index: 1;
    margin-top: 6%;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .nav-item {
    padding-bottom: 1%;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 0px;
    margin-top: -17px;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    top: 0;
    right: 0;
    font-size: 1.8rem;
    cursor: pointer;
    margin-left: auto;
    margin-right: 10%;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
    font-family: "bungee";
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}

.buyTacoCat {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffbd0c;
  height: 35px;
  width: 140px;
  border-radius: 30px;
  border: 1px solid #dc7f06;

  font-family: "Bungee";
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
  text-shadow: -1px 0 #dc7f06, 0 1px #dc7f06, 1px 0 #dc7f06, 0 -1px #dc7f06;

  @media (max-width: 1120px) {
    margin-bottom: 4%;
  }

  @media (max-width: 650px) {
    margin: 3% auto;
    margin-bottom: 5%;
    text-align: center;
  }
}

@media screen and (max-width: 650px) {
  .menu-icon {
    margin-right: 8%;
  }

  .nav-menu {
    top: 40px;
  }
}

@media screen and (max-width: 500px) {
  .nav-menu {
    top: 50px;
  }
}

@media screen and (max-width: 420px) {
  .nav-menu {
    top: 55px;
  }
}

.buyTacoCatHamburger {
  display: flex;
  align-items: center;
  text-decoration: none;
  background-color: #ffbd0c;
  height: 35px;
  width: 140px;
  border-radius: 30px;
  border: 1px solid #dc7f06;
  text-decoration: none;
  margin-left: 65px;
  margin-top: 22px;
  width: 160px;
  height: 40px;

  @media (max-width: 1120px) {
    margin-bottom: 4%;
  }

  @media (max-width: 650px) {
    margin: 3% auto;
    margin-bottom: 5%;
    text-align: center;
  }
}

.buyTacoCatCTAHamburger {
  font-family: "Bungee";
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  text-shadow: -1px 0 #dc7f06, 0 1px #dc7f06, 1px 0 #dc7f06, 0 -1px #dc7f06;
  margin: auto;
}

@media screen and (max-width: 650px) {
  .menu-icon {
    margin-right: 8%;
  }

  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    margin-top: 0;
    width: 44%;
  }
}
